import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import { Flex } from "../components/FlexBox";
import { Paragraph, ParagraphDiv } from "../components/parts/typography";

const NotFoundPage = () => {
    return (
        <Layout>
            <Flex flexDirection="column">
                <Paragraph mb="40px">
                    404 - Diese Seite existiert leider nicht! Zurück zur
                    HOMEPAGE.
                </Paragraph>
                <Link to="/" style={{ color: "black" }}>
                    Back to home
                </Link>
                .
            </Flex>
        </Layout>
    );
};

export default NotFoundPage;
